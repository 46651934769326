///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { MenuItem, Select } from '@mui/material'
import { Trans } from 'react-i18next'
import { rLIB } from 'rfbp_core/localization/library'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_DESIGN: JSX.Element = <Trans>Design</Trans>
const s_ELECTRICAL: JSX.Element = <Trans>Electrical</Trans>
const s_FIELD_SERVICE: JSX.Element = <Trans>Field Service</Trans>
const s_FINANCING: JSX.Element = <Trans>Financing</Trans>
const s_INSPECTIONS: JSX.Element = <Trans>Inspections</Trans>
const s_INSTALL: JSX.Element = <Trans>Install</Trans>
const s_INTERCONNECTION_AND_ACTIVATION: JSX.Element = <Trans>Interconnection and Activation</Trans>
const s_PERMITTING_AND_APPROVALS: JSX.Element = <Trans>Permitting and Approvals</Trans>
const s_REPAIR: JSX.Element = <Trans>Repair</Trans>
const s_SALE: JSX.Element = <Trans>Sale</Trans>
const s_SALES_PARTNER: JSX.Element = <Trans>Sales Partner</Trans>
const s_SITE_AUDIT: JSX.Element = <Trans>Site Audit</Trans>
const s_SUBCONTRACTOR: JSX.Element = <Trans>Subcontractor</Trans>
const s_ONBOARDING: JSX.Element = <Trans>Onboarding</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

export const globalTaskTypes: TsInterface_UnspecifiedObject = {
  custom_additional_work: {
    key: 'custom_additional_work',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Custom Additional Work',
    name: rLIB('Custom Additional Work'),
    additional_work_task: true,
    value: rLIB('Custom Additional Work'),
    task_category: 'project',
  },
  design: {
    key: 'design',
    associated_project_phase_key: 'sale_to_cap',
    name_string: 'Design',
    name: s_DESIGN,
    additional_work_task: false,
    value: s_DESIGN,
    task_category: 'project',
  },
  electrical: {
    key: 'electrical',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Electrical',
    name: s_ELECTRICAL,
    additional_work_task: true,
    value: s_ELECTRICAL,
    task_category: 'project',
  },
  field_service: {
    key: 'field_service',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Field Service',
    name: s_FIELD_SERVICE,
    additional_work_task: true,
    value: s_FIELD_SERVICE,
    task_category: 'project',
  },
  financing: {
    key: 'financing',
    associated_project_phase_key: 'install_to_activation',
    name_string: 'Financing',
    name: s_FINANCING,
    additional_work_task: false,
    value: s_FINANCING,
    task_category: 'project',
  },
  inspections: {
    key: 'inspections',
    associated_project_phase_key: 'install_to_activation',
    name_string: 'Inspections',
    name: s_INSPECTIONS,
    additional_work_task: false,
    value: s_INSPECTIONS,
    task_category: 'project',
  },
  install: {
    key: 'install',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Install',
    name: s_INSTALL,
    additional_work_task: false,
    value: s_INSTALL,
    task_category: 'project',
  },
  activation: {
    key: 'activation',
    associated_project_phase_key: 'install_to_activation',
    name_string: 'Activation',
    name: s_INTERCONNECTION_AND_ACTIVATION,
    additional_work_task: false,
    value: s_INTERCONNECTION_AND_ACTIVATION,
    task_category: 'project',
  },
  permitting_and_approvals: {
    key: 'permitting_and_approvals',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Permitting and Approvals',
    name: s_PERMITTING_AND_APPROVALS,
    additional_work_task: false,
    value: s_PERMITTING_AND_APPROVALS,
    task_category: 'project',
  },
  repair: {
    key: 'repair',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Repair',
    name: s_REPAIR,
    additional_work_task: true,
    value: s_REPAIR,
    task_category: 'project',
  },
  sale: {
    key: 'sale',
    associated_project_phase_key: 'sale_to_cap',
    name_string: 'Sale',
    name: s_SALE,
    additional_work_task: false,
    value: s_SALE,
    task_category: 'project',
  },
  sales_partner: {
    key: 'sales_partner',
    associated_project_phase_key: 'sale_to_cap',
    name_string: 'Sales Partner',
    name: s_SALES_PARTNER,
    additional_work_task: false,
    value: s_SALES_PARTNER,
    task_category: 'project',
  },
  site_audit: {
    key: 'site_audit',
    associated_project_phase_key: 'sale_to_cap',
    name_string: 'Site Audit',
    name: s_SITE_AUDIT,
    additional_work_task: false,
    value: s_SITE_AUDIT,
    task_category: 'project',
  },
  subcontractor: {
    key: 'subcontractor',
    associated_project_phase_key: 'cap_to_install',
    name_string: 'Subcontractor',
    name: s_SUBCONTRACTOR,
    additional_work_task: true,
    value: s_SUBCONTRACTOR,
    task_category: 'project',
  },
  onboarding: {
    key: 'onboarding',
    name_string: 'Onboarding',
    name: s_ONBOARDING,
    value: s_ONBOARDING,
    task_category: 'onboarding',
  },
}

export const globalTaskCategories: TsInterface_UnspecifiedObject = {
  project: {
    key: 'project',
    name_string: 'Project',
    name: rLIB('Project'),
    value: rLIB('Project'),
  },
  onboarding: {
    key: 'onboarding',
    name_string: 'Onboarding',
    name: rLIB('Onboarding'),
    value: rLIB('Onboarding'),
  },
}

export const rJSX_TaskTypeSelector = (us_taskType: any, us_setTaskType: any, taskTypes: 'all' | 'additional_work', taskCategory: string): JSX.Element => {
  let taskTypesArray: TsInterface_UnspecifiedObject[] = []
  if (taskTypes === 'additional_work') {
    taskTypesArray = objectToArray(globalTaskTypes).filter((taskType: TsInterface_UnspecifiedObject) => taskType.additional_work_task === true)
  } else {
    taskTypesArray = objectToArray(globalTaskTypes)
  }
  taskTypesArray = taskTypesArray.filter((taskType: TsInterface_UnspecifiedObject) => taskType.task_category === taskCategory)
  let taskTypeSelectorJSX = (
    <Select
      sx={{ verticalAlign: 'top' }}
      className="bp_thin_select_input tw-mr-2"
      color="primary"
      value={us_taskType || ''}
      onChange={(event: any) => {
        if (event != null && event.target != null && event.target.value != null) {
          us_setTaskType(event.target.value)
        }
      }}
      variant="outlined"
    >
      {taskTypesArray.map((role: TsInterface_UnspecifiedObject, index: number) => (
        <MenuItem
          key={index}
          value={role['key']}
        >
          {role.value}
        </MenuItem>
      ))}
    </Select>
  )
  return taskTypeSelectorJSX
}

export const rJSX_TaskCategorySelector = (us_taskCategory: any, us_setTaskCategory: any, us_setTaskType?: any): JSX.Element => {
  let taskCategoryArray: TsInterface_UnspecifiedObject[] = objectToArray(globalTaskCategories)

  let taskCategorySelectorJSX = (
    <Select
      sx={{ verticalAlign: 'top' }}
      className="bp_thin_select_input tw-mr-2"
      color="primary"
      value={us_taskCategory || ''}
      onChange={(event: any) => {
        if (event != null && event.target != null && event.target.value != null) {
          us_setTaskCategory(event.target.value)
          let taskTypesArray = objectToArray(globalTaskTypes)
          taskTypesArray = taskTypesArray.filter((taskType: TsInterface_UnspecifiedObject) => taskType.task_category === event.target.value)
          us_setTaskType(taskTypesArray[0].key)
        }
      }}
      variant="outlined"
    >
      {taskCategoryArray.map((role: TsInterface_UnspecifiedObject, index: number) => (
        <MenuItem
          key={index}
          value={role['key']}
        >
          {role.value}
        </MenuItem>
      ))}
    </Select>
  )
  return taskCategorySelectorJSX
}
